import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"align":"center"}},[_c('h3',[_vm._v("FINALIZA PEDIDO")]),_c(VDivider,{attrs:{"thickness":"8px"}})],1)],1),_c(VRow,[_c(VCol,[_c(VCard,{attrs:{"color":"rgba(0,77,154,100)"}},[_c(VCardText,[_c(VRow,[_c(VCol,[_c('div',{staticClass:"subtitle-3",staticStyle:{"color":"white"}},[_vm._v(" Pedido Surtido: "+_vm._s(_vm.bodyForFetch2[0].NoBitacora)+" ")])])],1),_c(VRow,[_c(VCol,[_c('div',{staticClass:"subtitle-3",staticStyle:{"color":"white"}},[_vm._v(" Tipo de Pedido: "+_vm._s(_vm.bodyForFetch2[0].TipoAbrev)+" ")])])],1),_c(VRow,[_c(VCol,[_c('div',{staticClass:"subtitle-3",staticStyle:{"color":"white"}},[_vm._v(" Sucursal Pide: "+_vm._s(_vm.bodyForFetch2[0].SucPide)+" ")])])],1)],1)],1)],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"block":"","depressed":"","dark":"","hide-details":"","color":"rgba(206,18,45,100)"},on:{"click":function($event){return _vm.hacerTraspaso()}}},[_vm._v(" finalizar pedido ")])],1)],1),_c(VDialog,{attrs:{"width":"auto"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardText,[_c('br'),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,[_vm._v(" El pedido "+_vm._s(_vm.bodyForFetch2[0].NoBitacora)+" ha sido actualizado correctamente")])],1),_c(VRow,[_c(VBtn,{attrs:{"color":"rgba(0,77,154,100)","dark":"","block":""},on:{"click":function($event){return _vm.salir()}}},[_vm._v("Aceptar")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
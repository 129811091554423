<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="justify-center text-center"
            >Consulta Pendientes</v-card-title
          >
          <v-divider style="border-width: thin 0 2px 0" />
          <v-card-text>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="sucursal"
                          label="Suc. Destino"
                          readonly
                          outlined
                          hide-details
                          dense
                          :color="color"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col sm="6" cols="6">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              label="Fecha Inicial"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              dense
                              outlined
                              hide-details
                              :color="color"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date"
                            no-title
                            scrollable
                            locale="es-ES"
                            @input="menu = false"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col sm="6" cols="6">
                        <v-menu
                          ref="menu3"
                          v-model="menu3"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          left
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date1"
                              label="Fecha Final"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              dense
                              outlined
                              hide-details
                              :color="color"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date1"
                            @input="menu3 = false"
                            no-title
                            scrollable
                            locale="es-ES"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-autocomplete
                          v-model="responsable"
                          dense
                          :items="responsables"
                          item-text="Responsable"
                          item-value="IdResponsable"
                          hide-details
                          label="Responsable"
                          outlined
                          :color="color"
                          ref="comboResponsable"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-autocomplete
                          v-model="operador"
                          dense
                          :items="operadores"
                          item-text="Nombre"
                          item-value="IdOperador"
                          hide-details
                          label="Operador"
                          outlined
                          :color="color"
                          ref="comboOperador"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="vehiculo"
                          label="Vehiculo"
                          ref="codigoBarra"
                          clearable
                          dense
                          outlined
                          type="number"
                          :color="color"
                          @keydown.enter.prevent="validaVehiculo()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-subtitle>{{
                    "Supervisor ( Optional )"
                  }}</v-card-subtitle>
                  <v-card-text>
                    <v-row>
                      <v-col sm="6" cols="6">
                        <v-text-field
                          v-model="usuario"
                          label="Cuenta"
                          clearable
                          dense
                          outlined
                          :disabled="activaSupervisor"
                          :color="color"
                          ref="usuario"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="6">
                        <v-text-field
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show1 ? 'text' : 'password'"
                          name="input-10-2"
                          label="Password"
                          v-model="password"
                          class="input-group--focused"
                          @click:append="show1 = !show1"
                          dense
                          outlined
                          :disabled="activaSupervisor"
                          :color="color"
                          clearable
                          ref="password"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn dark block :color="color" @click="liberarEmbarque()"
                  >Liberar</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogPresentaFolios" width="auto" persistent>
      <v-card>
        <v-card-text>
          <br />
          <v-row justify="center">
            <v-col cols="12">
              <h3>Folio(s) Generado(s)</h3>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12">
              <h3>Sucursal: Folio</h3>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12">
              <h3>{{ foliosEmbarqueGenerados }}</h3>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark text :color="color" @click="regresarConsultaPendientes()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import sound from "../assets/error.wav";
export default {
  data: () => ({
    sucursal: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date1: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000 + 86400000
    )
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu3: false,
    responsable: "",
    responsables: [],
    operador: "",
    operadores: [],
    vehiculo: "",
    usuario: "",
    show1: false,
    password: "",
    activaSupervisor: true,
    codigoBarraFocus: null,
    comboOperadorFocus: null,
    comboResponsableFocus: null,
    usuarioFocus: null,
    passwordFocus: null,
    foliosEmbarqueGenerados: "",
    dialogPresentaFolios: false,
  }),
  computed: {
    ...mapState(["url", "color", "secondColor"]),
    isAuthorized: {
      get: function () {
        return this.$store.state.isAuthorized;
      },
      set: function (val) {
        this.$store.state.isAuthorized = val;
      },
    },
    userInfo: {
      get: function () {
        return this.$store.state.userInfo;
      },
      set: function (val) {
        this.$store.state.userInfo = val;
      },
    },
    token: {
      get: function () {
        return this.$store.state.token;
      },
      set: function (val) {
        this.$store.state.token = val;
      },
    },
    dbSelected: {
      get: function () {
        return this.$store.state.dbSelected;
      },
      set: function (val) {
        localStorage.setItem("dbSelected", val);
        this.$store.commit("setdbSelected", val);
      },
    },
    hayEmpaquesPendientes: {
      get: function () {
        return this.$store.state.hayEmpaquesPendientes;
      },
      set: function (val) {
        this.$store.commit("setHayEmpaquesPendientes", val);
      },
    },
    sucursalesSeleccionadas: {
      get: function () {
        return this.$store.state.sucursalesSeleccionadas;
      },
      set: function (val) {
        this.$store.commit("setSucursalesSeleccionadas", val);
      },
    },
  },
  async mounted() {
    const credenciales = await JSON.parse(localStorage.getItem("credenciales"));
    if (credenciales != null) {
      this.token = credenciales.token;
      this.userInfo = credenciales.userInfo;
      this.isAuthorized = credenciales.isAuthorized;
    }
    const dbSelect = localStorage.getItem("dbSelected");
    if (dbSelect != null) {
      this.dbSelected = parseInt(dbSelect);
    }
    this.codigoBarraFocus = this.$refs.codigoBarra;
    this.comboOperadorFocus = this.$refs.comboOperador;
    this.comboResponsableFocus = this.$refs.comboResponsable;
    this.usuarioFocus = this.$refs.usuario;
    this.passwordFocus = this.$refs.password;
    if (this.sucursalesSeleccionadas == "") {
      this.$router.push({ name: "ConsultaPendientes" });
      return;
    }
    this.sucursal = this.sucursalesSeleccionadas;
    this.activaSupervisor = !this.hayEmpaquesPendientes;
    this.TraerResponsablesTraspaso();
    this.TraerOperadores();
  },
  methods: {
    async TraerResponsablesTraspaso() {
      try {
        const response = await fetch(
          this.url +
            "/android/traer-responsables-traspaso?db=" +
            this.dbSelected +
            "&idEventoT=17&Sucursal=" +
            this.userInfo.sucursal,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          if (value.length > 0) {
            this.responsables = value;
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "Ocurrio un error.",
            });
          }
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async TraerOperadores() {
      try {
        const response = await fetch(
          this.url +
            "/android/traer-operadores?db=" +
            this.dbSelected +
            "&sucursal=" +
            this.userInfo.sucursal,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          if (value.length > 0) {
            this.operadores = value;
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "Ocurrio un error.",
            });
          }
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async validaVehiculo() {
      try {
        if (this.vehiculo == "") {
          this.errorSound();
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Ingrese un vehiculo.",
          });
          return;
        }
        const response = await fetch(
          this.url +
            "/android/valida-vehiculo-jomar?db=" +
            this.dbSelected +
            "&IdVehiculo=" +
            this.vehiculo,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          if (value[0].Existe != 1) {
            this.errorSound();
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "Vehiculo no encontrado.",
            });
            this.vehiculo = "";
          }
        } else {
          this.vehiculo = "";
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        this.vehiculo = "";
        console.log(error);
      }
    },
    errorSound() {
      var audio = new Audio(sound);
      audio.play();
    },
    async liberarEmbarque() {
      try {
        if (this.responsable == "" || this.responsable == null) {
          this.errorSound();
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Seleccione un responsable.",
          });
          this.comboResponsableFocus.$el.querySelector("input").focus();
          return;
        }
        if (this.operador == "" || this.operador == null) {
          this.errorSound();
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Seleccione un operador.",
          });
          this.comboOperadorFocus.$el.querySelector("input").focus();
          return;
        }
        await this.validaVehiculo();
        if (this.vehiculo == "") {
          this.errorSound();
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Ingrese un vehiculo.",
          });
          this.codigoBarraFocus.$el.querySelector("input").focus();
          return;
        }
        const date = new Date(this.date);
        const date1 = new Date(this.date1);

        if (date > date1) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "La fecha inicial no puede ser mayor que la fecha final.",
          });
          return;
        }
        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}${month}${day}`;
        };
        const formattedDate = formatDate(date);
        const formattedDate1 = formatDate(date1);

        if (this.usuario == "" && this.hayEmpaquesPendientes) {
          this.errorSound();
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Ingrese un usuario.",
          });
          this.usuarioFocus.$el.querySelector("input").focus();
          return;
        }
        if (this.password == "" && this.hayEmpaquesPendientes) {
          this.errorSound();
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Ingrese un password.",
          });
          this.passwordFocus.$el.querySelector("input").focus();
          return;
        }
        if (this.hayEmpaquesPendientes) {
          const valido = await this.validaClaveSupervisor();
          if (valido == 0) {
            this.errorSound();
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "Usuario o contraseña no son validos, favor de verificar.",
            });
            return;
          }
        }
        const body = {
          UsuarioId: this.userInfo.idUsuario,
          Maquina: "TC210K",
          Vehiculo: this.vehiculo,
          IdResponsable: this.responsable,
          IdOperador: this.operador,
          SucOrigen: this.userInfo.sucursal,
          SucDest: this.sucursalesSeleccionadas,
          FechaIni: formattedDate,
          FechaFin: formattedDate1,
          db: this.dbSelected,
        };
        const response = await fetch(
          this.url + "/android/actualiza-estatus-embarque",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify(body),
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          if (value.length > 0) {
            console.log(value);
            this.foliosEmbarqueGenerados = await value
              .map(
                (embarque) => `${embarque.Sucursal}: ${embarque.FolioEmbarque}`
              )
              .join("\n");
            this.dialogPresentaFolios = true;
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "No se encontro un embarque en progreso con los filtros seleccionados.",
            });
          }
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async validaClaveSupervisor() {
      try {
        const response = await fetch(this.url + "/android/valida-supervisor", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            Cuenta: this.usuario,
            Password: this.password,
            db: this.dbSelected,
          }),
        });
        const value = await response.json();
        if (response.status == 200) {
          return value[0].Existe;
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
          return 0;
        }
      } catch (error) {
        return 0;
        console.log(error);
      }
    },
    regresarConsultaPendientes() {
      this.foliosEmbarqueGenerados = "";
      this.sucursalesSeleccionadas = "";
      this.hayEmpaquesPendientes = false;
      this.dialogPresentaFolios = false;
      this.$router.push({ name: "ConsultaPendientes" });
    },
  },
};
</script>
<style></style>

<template>
  <v-container>
    <v-row>
      <v-col align="center">
        <h3>BUSCAR PEDIDO</h3>
        <v-divider thickness="8px" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="Folio"
          v-model="folio"
          variant="underlined"
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          block
          depressed
          dark
          hide-details
          color="rgba(206,18,45,100)"
          :disabled="btnDisableCheck()"
          v-on:click="BuscaPedidoFolio()"
        >
          Buscar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card color="rgba(0,77,154,100)">
          <v-card-text>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Pedido a Surtir: {{ pedidoASurtirText }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Tipo de Pedido: {{ tipoDePedidoText }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Sucursal Pide: {{ sucursalPideText }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Lineas: {{ lineasText }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Piezas: {{ piezasText }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          block
          depressed
          dark
          hide-details
          color="rgba(206,18,45,100)"
          :disabled="surtirButton"
          v-on:click="SurtirPedidoF()"
        >
          Surtir
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="alerta" width="auto">
      <v-card>
        <v-card-text>
          <br />
          <v-row justify="center">
            <v-col> No se encontro el pedido con ese folio. </v-col>
          </v-row>
          <v-row>
            <v-btn color="rgba(0,77,154,100)" dark block @click="alerta = false"
              >OK</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    folio: "",
    resultadoFolio: [],
    pedidoASurtirText: "",
    tipoDePedidoText: "",
    sucursalPideText: "",
    lineasText: "",
    piezasText: "",
    alerta: false,
    surtirButton: true,
  }),
  computed: {
    ...mapState(["url", "userInfo", "token"]),
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
    almacenPed: {
      get: function () {
        return this.$store.state.almacenPed;
      },
      set: function (val) {
        this.$store.commit("setAlmacenPed", val);
      },
    },
  },
  methods: {
    btnDisableCheck() {
      if (this.folio != 0) {
        return false;
      } else {
        return true;
      }
    },
    async BuscaPedidoFolio() {
      try {
        const response = await fetch(
          this.url + "/android/surtir_sugiere_pedido",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              Sucursal: this.userInfo.sucursal,
              Almacen: "",
              TodoAlmacen: 0,
              UsuarioId: this.userInfo.idUsuario,
              TipoTraspaso: 0,
              NoBitacora: this.folio.trim(),
              zona: "TODOS",
            }),
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          this.resultadoFolio = value.resultado;
          if (this.resultadoFolio.length == 0) {
            this.alerta = true;
          } else {
            this.fillCardText();
            this.surtirButton = false;
          }
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    fillCardText() {
      this.pedidoASurtirText = this.resultadoFolio[0].NoBitacora;
      this.tipoDePedidoText = this.resultadoFolio[0].TipoAbrev;
      this.sucursalPideText = this.resultadoFolio[0].SucPide;
      this.lineasText = this.resultadoFolio[0].Partidas;
      this.piezasText = this.resultadoFolio[0].PzasTot;
      this.almacenPed = this.resultadoFolio[0].Almacen;
    },
    async SurtirPedidoF() {
      try {
        const response = await fetch(
          this.url + "/android/surtir_agarra_pedido",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              SucPide: this.resultadoFolio[0].SucPide,
              SucTras: this.resultadoFolio[0].SucTras,
              Pedido: this.resultadoFolio[0].NoBitacora,
              Almacen: this.resultadoFolio[0].Almacen,
              UsuarioId: this.userInfo.idUsuario,
              Responsable: this.resultadoFolio[0].Responsable,
            }),
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          var resultadoSurtido = value.resultado;
          this.bodyForFetch = {
            SucPide: resultadoSurtido[0].SucPide,
            SucTras: resultadoSurtido[0].SucTras,
            Pedido: resultadoSurtido[0].NoBitacora,
          };
          let routeData = this.$router.resolve({
            name: "ComprobacionSurtido",
          });
          window.open(routeData.href, "_self");
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style></style>

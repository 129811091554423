import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VContainer,[_c(VRow,{staticStyle:{"padding-bottom":"3px"}},[_c(VCol,{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('h3',[_vm._v("Conteos Pendientes")])])],1),_c(VDivider,{staticStyle:{"border-width":"thin 0 2px 0"}}),_c(VRow,{staticStyle:{"padding-top":"10px","padding-bottom":"17px"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.listaTipoFil,"outlined":"","dense":"","label":"Filtrar por: ","item-text":"descripcion","item-value":"idTipo","hide-details":""},model:{value:(_vm.tipoFiltro),callback:function ($$v) {_vm.tipoFiltro=$$v},expression:"tipoFiltro"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"block":"","color":_vm.color,"dark":""},on:{"click":function($event){return _vm.Filtrar()}}},[_vm._v(" FILTRAR ")])],1)],1),_c(VDivider,{staticStyle:{"border-width":"thin 0 2px 0"}}),_c(VRow,{staticStyle:{"padding-top":"0px"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VList,{attrs:{"dense":""}},[_c(VSubheader,[_vm._v("Selecciona un folio:")]),_c(VListItemGroup,{attrs:{"color":"primary"}},_vm._l((_vm.ListaPendientes),function(item,i){return _c(VListItem,{key:i,on:{"click":function($event){return _vm.FolioSel(item)}}},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.Comentario)}})],1)],1)}),1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="justify-center text-center">
            CONSULTA PENDIENTES
          </v-card-title>
          <v-divider style="border-width: thin 0 2px 0" />
          <v-card-text>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="sucSelected"
                  dense
                  :items="sucursales"
                  item-text="sucursal"
                  item-value="sucursal"
                  hide-details
                  label="Sucursal"
                  multiple
                  small-chips
                  outlined
                  :color="color"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6" cols="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Fecha Inicial"
                      prepend-icon="mdi-calendar"
                      readonly
                      offset-y
                      v-bind="attrs"
                      v-on="on"
                      dense
                      outlined
                      :color="color"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    scrollable
                    locale="es-ES"
                    @input="menu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col sm="6" cols="6">
                <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  left
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date1"
                      label="Fecha Final"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      outlined
                      hide-details
                      :color="color"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date1"
                    @input="menu3 = false"
                    no-title
                    scrollable
                    locale="es-ES"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="tipoTraspasoSelecionados"
                  dense
                  :items="listaTipoTraspaso"
                  item-text="TipoTraspaso"
                  item-value="TipoTraspasoId"
                  hide-details
                  label="Tipo Traspaso"
                  multiple
                  small-chips
                  outlined
                  :color="color"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6" cols="6">
                <v-btn block dark :color="color" v-on:click="buscaPendientes()"
                  >Buscar</v-btn
                >
              </v-col>
              <v-col sm="6" cols="6">
                <v-btn
                  block
                  :dark="!btnSwitch"
                  :disabled="btnSwitch"
                  :color="color"
                  v-on:click="liberarEmbarque()"
                  >Liberar</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  dense
                  :headers="headers"
                  :items="listaPendientes"
                  class="row-height-50 elevation-1"
                  :mobile-breakpoint="0"
                  :items-per-page="listaPendientes.length"
                  hide-default-footer
                >
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogPreventivo" width="auto">
      <v-card>
        <v-card-text>
          <br />
          <v-row justify="center">
            <v-col cols="2">
              <v-icon x-large>mdi-alert-circle</v-icon>
            </v-col>
            <v-col cols="10">
              <h3>Aun hay pendientes por embarcar, ¿Desea continuar?</h3>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn dark text :color="color" @click="dialogPreventivo = false">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn dark text :color="color" @click="pasarALiberarEmbarque()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    sucSelected: [],
    sucursales: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date1: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000 + 86400000
    )
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu3: false,
    tipoTraspasoSelecionados: [],
    listaTipoTraspaso: [],
    headers: [
      { text: "Sucursal", value: "SucDest" },
      { text: "Folio Empaque", value: "FolioEmpaque" },
      { text: "Folio Tarima", value: "NoTarima" },
    ],
    listaPendientes: [],
    btnSwitch: true,
    dialogPreventivo: false,
  }),
  computed: {
    ...mapState(["url", "color", "secondColor"]),
    isAuthorized: {
      get: function () {
        return this.$store.state.isAuthorized;
      },
      set: function (val) {
        this.$store.state.isAuthorized = val;
      },
    },
    userInfo: {
      get: function () {
        return this.$store.state.userInfo;
      },
      set: function (val) {
        this.$store.state.userInfo = val;
      },
    },
    token: {
      get: function () {
        return this.$store.state.token;
      },
      set: function (val) {
        this.$store.state.token = val;
      },
    },
    dbSelected: {
      get: function () {
        return this.$store.state.dbSelected;
      },
      set: function (val) {
        localStorage.setItem("dbSelected", val);
        this.$store.commit("setdbSelected", val);
      },
    },
    hayEmpaquesPendientes: {
      get: function () {
        return this.$store.state.hayEmpaquesPendientes;
      },
      set: function (val) {
        this.$store.commit("setHayEmpaquesPendientes", val);
      },
    },
    sucursalesSeleccionadas: {
      get: function () {
        return this.$store.state.sucursalesSeleccionadas;
      },
      set: function (val) {
        this.$store.commit("setSucursalesSeleccionadas", val);
      },
    },
  },
  async mounted() {
    const credenciales = await JSON.parse(localStorage.getItem("credenciales"));
    if (credenciales != null) {
      this.token = credenciales.token;
      this.userInfo = credenciales.userInfo;
      this.isAuthorized = credenciales.isAuthorized;
    }
    const dbSelect = localStorage.getItem("dbSelected");
    if (dbSelect != null) {
      this.dbSelected = parseInt(dbSelect);
    }
    this.traerSucursales();
    this.traerTipoTraspasos();
  },
  methods: {
    async traerSucursales() {
      try {
        const response = await fetch(
          this.url + "/users/sucursalesNvo?db=" + this.dbSelected,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          if (value.length > 0) {
            this.sucursales = value;
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "Ocurrio un error.",
            });
          }
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async traerTipoTraspasos() {
      try {
        const response = await fetch(
          this.url + "/android/traer_tipos_traspasos?db=" + this.dbSelected,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          if (value.length > 0) {
            this.listaTipoTraspaso = value;
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "Ocurrio un error.",
            });
          }
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async buscaPendientes() {
      try {
        if (this.sucSelected == "") {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Seleccione una sucursal.",
          });
          return;
        }
        if (this.tipoTraspasoSelecionados.length == 0) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Seleccione un tipo de traspaso.",
          });
          return;
        }

        const date = new Date(this.date);
        const date1 = new Date(this.date1);

        if (date > date1) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "La fecha inicial no puede ser mayor que la fecha final.",
          });
          return;
        }

        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}${month}${day}`;
        };
        const formattedDate = formatDate(date);
        const formattedDate1 = formatDate(date1);
        const sucursalesString = this.sucSelected.join(",");
        const tipoTraspasoString = this.tipoTraspasoSelecionados.join(",");

        const response = await fetch(this.url + "/android/consulta-embarque", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            SucursalDest: sucursalesString,
            FechaINI: formattedDate,
            FechaFIN: formattedDate1,
            TipoTraspaso: tipoTraspasoString,
            SucOrigen: this.userInfo.sucursal,
            db: this.dbSelected,
          }),
        });
        const value = await response.json();
        if (response.status == 200) {
          if (value.length > 0) {
            this.listaPendientes = value;
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "No se encontraron pendientes.",
            });
          }
          this.btnSwitch = false;
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    liberarEmbarque() {
      if (this.listaPendientes.length > 0) {
        this.dialogPreventivo = true;
        return;
      }
      this.pasarALiberarEmbarque();
    },
    async pasarALiberarEmbarque() {
      if (this.listaPendientes.length > 0) {
        this.hayEmpaquesPendientes = true;
      } else {
        this.hayEmpaquesPendientes = false;
      }
      this.sucursalesSeleccionadas = await this.sucSelected.join(",");
      this.dialogPreventivo = false;
      this.$router.push({ name: "LiberarEmbarque" });
    },
  },
};
</script>
<style scoped></style>

<template lang="">
  <v-container>
    <v-row>
      <v-col align="center">
        <h3>SURTIDO {{ "(COMPROBACION)" }}</h3>
        <v-divider thickness="8px" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          block
          depressed
          dark
          hide-details
          v-on:click="surtirSugerido()"
          color="rgba(206,18,45,100)"
        >
          surtir sugerido
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          block
          depressed
          dark
          hide-details
          color="rgba(206,18,45,100)"
          v-on:click="AbrirTabla()"
        >
          ver lista
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="dialog">
      <v-col>
        <v-card color="rgba(0,77,154,100)">
          <v-card-text>
            <v-data-table
              dense
              :headers="pedidosHeaders"
              :items="pedidos"
              mobile-breakpoint="0"
              @click:row="handleRowClick"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    pedidosHeaders: [
      { text: "Disp. Traspaso", value: "Existencia" },
      { text: "Articulo", value: "Itemno" },
      { text: "Localizacion", value: "Localizacion" },
    ],
    pedidos: [],
    dialog: false,
  }),
  computed: {
    ...mapState(["url", "bodyForFetch", "token"]),
    bodyForFetch2: {
      get: function () {
        return this.$store.state.bodyForFetch2;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch2", val);
      },
    },
  },
  methods: {
    async AbrirTabla() {
      try {
        const response = await fetch(
          this.url + "/android/surtir_pedido_tabla",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify(this.bodyForFetch),
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          this.pedidos = value.resultado;
          this.dialog = !this.dialog;
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async handleRowClick(rowvalue) {
      try {
        const response = await fetch(
          this.url + "/android/traer_info_articulo",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              SucPide: this.bodyForFetch.SucPide,
              SucTras: this.bodyForFetch.SucTras,
              Pedido: this.bodyForFetch.Pedido,
              itemno: rowvalue.Itemno,
            }),
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          this.irASurtirPedido(value.resultado);
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async surtirSugerido() {
      try {
        const response = await fetch(this.url + "/android/sugiere_articulo", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            SucPide: this.bodyForFetch.SucPide,
            SucTras: this.bodyForFetch.SucTras,
            Pedido: this.bodyForFetch.Pedido,
          }),
        });
        const value = await response.json();
        if (response.status == 200) {
          this.irASurtirPedido(value.resultado);
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    irASurtirPedido(value) {
      this.bodyForFetch2 = value;
      let routeData = this.$router.resolve({
        name: "SurtidoPedido",
      });
      window.open(routeData.href, "_self");
    },
  },
};
</script>
<style lang=""></style>

<template lang="">
  <v-container>
    <v-row>
      <v-col align="center">
        <h3>BUSCAR PEDIDO</h3>
        <v-divider thickness="8px" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-autocomplete
          v-model="almacen"
          hide-details
          solo
          :items="almacenes"
          item-text="almacen"
          item-value="almacen"
          label="Almacen"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          v-model="tipotrasp"
          hide-details
          solo
          :items="trasplist"
          item-value="TipoTraspasoId"
          item-text="Descripcion"
          label="Tipo Traspaso"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          hide-details
          solo
          :items="zonalist"
          v-model="zona"
          item-text="zona"
          item-value="zona"
          label="Zona"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          block
          depressed
          dark
          hide-details
          :disabled="btnDisableCheck()"
          v-on:click="BuscaPedidoFolio()"
          color="rgba(206,18,45,100)"
        >
          Buscar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card color="rgba(0,77,154,100)">
          <v-card-text>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Pedido a Surtir: {{ pedidoASurtirText }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Tipo de Pedido: {{ tipoDePedidoText }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Sucursal Pide: {{ sucursalPideText }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Lineas: {{ lineasText }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Piezas: {{ piezasText }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Desbloquear Pedido: {{ desbPedidoText }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  block
                  depressed
                  dark
                  hide-details
                  v-on:click="BuscaPedidoFolio()"
                  color="rgba(206,18,45,100)"
                >
                  refrescar
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  block
                  depressed
                  dark
                  hide-details
                  v-on:click="SurtirPedidoF()"
                  color="rgba(206,18,45,100)"
                >
                  Surtir
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="alerta" width="auto">
      <v-card>
        <v-card-text>
          <br />
          <v-row justify="center">
            <v-col> No se encontro el pedido con ese folio. </v-col>
          </v-row>
          <v-row>
            <v-btn color="rgba(0,77,154,100)" dark block @click="alerta = false"
              >OK</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    almacen: "",
    tipotrasp: "",
    zona: "",
    almacenes: [],
    trasplist: [],
    zonalist: [],
    pedidoASurtirText: "",
    tipoDePedidoText: "",
    sucursalPideText: "",
    lineasText: "",
    piezasText: "",
    desbPedidoText: "",
    resultadoFolio: [],
    alerta: false,
    dialog: false,
  }),
  computed: {
    ...mapState(["url"]),
    bodyForFetch: {
      get: function () {
        return this.$store.state.bodyForFetch;
      },
      set: function (val) {
        this.$store.commit("setBodyForFetch", val);
      },
    },
    almacenPed: {
      get: function () {
        return this.$store.state.almacenPed;
      },
      set: function (val) {
        this.$store.commit("setAlmacenPed", val);
      },
    },
    isAuthorized: {
      get: function () {
        return this.$store.state.isAuthorized;
      },
      set: function (val) {
        this.$store.state.isAuthorized = val;
      },
    },
    userInfo: {
      get: function () {
        return this.$store.state.userInfo;
      },
      set: function (val) {
        this.$store.state.userInfo = val;
      },
    },
    token: {
      get: function () {
        return this.$store.state.token;
      },
      set: function (val) {
        this.$store.state.token = val;
      },
    },
  },
  async mounted() {
    const credenciales = await JSON.parse(localStorage.getItem("credenciales"));
    if (credenciales != null) {
      //await this.$store.commit("setCredenciales", credenciales);
      this.token = credenciales.token;
      this.userInfo = credenciales.userInfo;
      this.isAuthorized = credenciales.isAuthorized;
    }
    this.traerListaAlmacenes();
    this.traerListaTipoPedidos();
    this.traerListaZonas();
  },
  methods: {
    btnDisableCheck() {
      var tipotrasCheck = false;
      if (this.tipotrasp !== "") {
        tipotrasCheck = true;
      }
      if (this.almacen != "" && this.zona != "" && tipotrasCheck == true) {
        return false;
      } else {
        return true;
      }
    },
    async traerListaAlmacenes() {
      try {
        const response = await fetch(this.url + "/android/llamar_almacenes", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        });
        const value = await response.json();
        if (response.status == 200) {
          this.almacenes = value.resultado;
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async traerListaTipoPedidos() {
      try {
        const response = await fetch(
          this.url + "/android/llamar_tipo_traspasos",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          this.trasplist = value.resultado;
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async traerListaZonas() {
      try {
        const response = await fetch(this.url + "/android/llamar_zonas", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        });
        const value = await response.json();
        if (response.status == 200) {
          this.zonalist = value.resultado;
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async BuscaPedidoFolio() {
      let almarr = this.almacen.split("-");
      try {
        const response = await fetch(
          this.url + "/android/surtir_sugiere_pedido",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              Sucursal: this.userInfo.sucursal,
              Almacen: almarr[0],
              TodoAlmacen: this.almacen == "TODOS" ? 1 : 0,
              UsuarioId: this.userInfo.idUsuario,
              TipoTraspaso: this.tipotrasp,
              NoBitacora: 0,
              zona: this.zona,
            }),
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          this.resultadoFolio = value.resultado;
          if (this.resultadoFolio.length == 0) {
            this.alerta = true;
          } else {
            this.fillCardText();
            this.surtirButton = false;
          }
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    fillCardText() {
      this.pedidoASurtirText = this.resultadoFolio[0].NoBitacora;
      this.tipoDePedidoText = this.resultadoFolio[0].TipoAbrev;
      this.sucursalPideText = this.resultadoFolio[0].SucPide;
      this.lineasText = this.resultadoFolio[0].Partidas;
      this.piezasText = this.resultadoFolio[0].PzasTot;
      this.almacenPed = this.resultadoFolio[0].Almacen;
    },
    async SurtirPedidoF() {
      try {
        const response = await fetch(
          this.url + "/android/surtir_agarra_pedido",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              SucPide: this.resultadoFolio[0].SucPide,
              SucTras: this.resultadoFolio[0].SucTras,
              Pedido: this.resultadoFolio[0].NoBitacora,
              Almacen: this.resultadoFolio[0].Almacen,
              UsuarioId: this.userInfo.idUsuario,
              Responsable: 0,
            }),
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          var resultadoSurtido = value.resultado;
          this.bodyForFetch = {
            SucPide: resultadoSurtido[0].SucPide,
            SucTras: resultadoSurtido[0].SucTras,
            Pedido: resultadoSurtido[0].NoBitacora,
          };
          let routeData = this.$router.resolve({
            name: "ComprobacionSurtido",
          });
          window.open(routeData.href, "_self");
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang=""></style>

import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VAppBar,{attrs:{"app":"","color":"#141428","dark":"","dense":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){_vm.navDrawerToggle = !_vm.navDrawerToggle}}}),_c('h3',{staticStyle:{"color":"white","font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.tittle)+" ")]),_c(VSpacer),_c('h3',{staticStyle:{"color":"white","font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.dbSelected == 0 ? "Jomar" : "JomarUSA")+" ")])],1),_c(VNavigationDrawer,{attrs:{"absolute":"","temporary":"","width":"285"},model:{value:(_vm.navDrawerToggle),callback:function ($$v) {_vm.navDrawerToggle=$$v},expression:"navDrawerToggle"}},[_c('div',{staticStyle:{"padding":"25px 0 25px 0","background":"#141428"}},[_c(VRow,[_c(VCol,{staticStyle:{"padding":"0","text-align":"-webkit-center"},attrs:{"cols":"12"}},[_c(VImg,{attrs:{"max-height":"160","max-width":"160","src":require("../assets/logo_new.png")}})],1)],1),_c(VRow,[_c(VCol,{staticStyle:{"padding-bottom":"0","padding-left":"25px"},attrs:{"cols":"12"}},[_c('h6',{staticStyle:{"color":"white","font-weight":"400"}},[_vm._v(" Jomar Industrias S.A. de C.V. ")]),_c('h6',{staticStyle:{"color":"white","font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.userInfo.nombre)+" ")])])],1)],1),_c(VDivider),_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"color":"primary"},model:{value:(_vm.navbarToggle),callback:function ($$v) {_vm.navbarToggle=$$v},expression:"navbarToggle"}},[_vm._l((_vm.navDrawerOpciones),function(item,i){return _c(VListItem,{key:i,on:{"click":function($event){return _vm.goRoute(item, i)}}},[_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(item.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),_c(VListItem,{on:{"click":function($event){return _vm.Exit()}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-exit-to-app")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Cerrar Sesión")])],1)],1)],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="">
  <v-container>
    <v-row>
      <v-col align="center">
        <h3>TARIMADO</h3>
        <v-divider style="border-width: thin 0 2px 0" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-autocomplete
          v-model="sucursalSeleccionada"
          :items="sucursales"
          item-text="sucursal"
          item-value="sucursal"
          hide-details
          :color="color"
          outlined
          dense
          ref="sucursalDestino"
          label="Sucursal Destino"
          @change="consultaTarimas"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="totalTarimas"
          label="Total Tarimas"
          clearable
          dense
          hide-details
          disabled
          :color="color"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="codigobarrastarima"
          label="Escanea Tarima"
          clearable
          dense
          ref="codigoBarraTarima"
          hide-details
          :color="color"
          outlined
          type="number"
          @focus="selectAllText"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-menu
          v-model="menuFechaInicio"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="fechaIn"
              label="Fecha Inicio"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              outlined
              :color="color"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fechaIn"
            no-title
            scrollable
            locale="es-ES"
            @input="menuFechaInicio = false"
          ></v-date-picker>
        </v-menu>
        <v-divider style="border-width: thin 0 2px 0" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="codigoBarras"
          label="Codigo Empaque"
          ref="codigoBarra"
          clearable
          dense
          hide-details
          :color="color"
          outlined
          @keydown.enter.prevent="BuscaEmpaque()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn block :color="color" dark @click="BuscaEmpaquesPendientes()"
          >Buscar pendientes</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="listaPendientes"
          class="row-height-50 elevation-1"
          :mobile-breakpoint="0"
          :items-per-page="listaPendientes.length"
          hide-default-footer
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import sound from "../assets/error.wav";
export default {
  name: "PantallaEntarimado",
  data: () => ({
    sucursalSeleccionada: "",
    sucursales: [],
    fechaIn: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menuFechaInicio: "",
    headers: [
      {
        text: "Empaque",
        value: "Empaque",
      },
      {
        text: "Fecha",
        value: "HoraTraspaso",
      },
      {
        text: "Almacen",
        value: "Almacen",
      },
    ],
    listaPendientes: [],
    totalTarimas: "",
    codigobarrastarima: "",
    codigoBarras: "",
    codigoBarraFocus: null,
    sucursalDestinoFocus: null,
    codigoBarraTarimaFocus: null,
  }),
  computed: {
    ...mapState(["color", "secondColor", "url"]),
    navbarToggle: {
      get: function () {
        return this.$store.state.navbarToggle;
      },
      set: function (val) {
        localStorage.setItem("navbarToggle", val);
        this.$store.commit("setNavbarToggle", val);
      },
    },
    isAuthorized: {
      get: function () {
        return this.$store.state.isAuthorized;
      },
      set: function (val) {
        this.$store.state.isAuthorized = val;
      },
    },
    userInfo: {
      get: function () {
        return this.$store.state.userInfo;
      },
      set: function (val) {
        this.$store.state.userInfo = val;
      },
    },
    token: {
      get: function () {
        return this.$store.state.token;
      },
      set: function (val) {
        this.$store.state.token = val;
      },
    },
    dbSelected: {
      get: function () {
        return this.$store.state.dbSelected;
      },
      set: function (val) {
        localStorage.setItem("dbSelected", val);
        this.$store.commit("setdbSelected", val);
      },
    },
  },
  async mounted() {
    this.codigoBarraFocus = this.$refs.codigoBarra;
    this.sucursalDestinoFocus = this.$refs.sucursalDestino;
    this.codigoBarraTarimaFocus = this.$refs.codigoBarraTarima;
    const credenciales = await JSON.parse(localStorage.getItem("credenciales"));
    if (credenciales != null) {
      this.token = credenciales.token;
      this.userInfo = credenciales.userInfo;
      this.isAuthorized = credenciales.isAuthorized;
    }
    const dbSelect = localStorage.getItem("dbSelected");
    if (dbSelect != null) {
      this.dbSelected = parseInt(dbSelect);
    }
    this.traerSucursales();
  },
  methods: {
    async traerSucursales() {
      try {
        const response = await fetch(
          this.url + "/users/sucursalesNvo?db=" + this.dbSelected,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          if (value.length > 0) {
            this.sucursales = value;
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "Ocurrio un error.",
            });
          }
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async consultaTarimas(sucursal) {
      try {
        const date = new Date();
        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}${month}${day}`;
        };
        const formattedDate = formatDate(date);
        const response = await fetch(
          this.url + "/android/busca-total-tarimas",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              fecha: formattedDate,
              Sucursal: sucursal,
              db: this.dbSelected,
            }),
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          this.totalTarimas = value[0].TotalTarimas;
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async BuscaEmpaque() {
      try {
        if (this.codigoBarras == "") {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Ingrese un codigo de barras.",
          });
          this.codigoBarras = "";
          return;
        }
        if (this.sucursalSeleccionada == "") {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Seleccione una sucursal.",
          });
          this.sucursalDestinoFocus.$el.querySelector("input").focus();
          this.codigoBarras = "";
          return;
        }
        if (this.codigobarrastarima == "" || this.codigobarrastarima <= 0) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Ingrese un codigo de barras de tarima.",
          });
          this.codigoBarraTarimaFocus.$el.querySelector("input").focus();
          this.codigoBarras = "";
          return;
        }
        const arr = this.codigoBarras.split("-");
        const traspaso = arr[0];
        const empaque = arr[1];
        const date = new Date(this.fechaIn);
        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}${month}${day}`;
        };
        const formattedDate = formatDate(date);
        const response = await fetch(this.url + "/android/consulta-empaque", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            SucDest: this.sucursalSeleccionada,
            SucOri: this.userInfo.sucursal,
            NoTraspaso: traspaso,
            NoEmpaque: empaque,
            fechaini: formattedDate,
            Tarima: this.codigobarrastarima,
            UsuarioEntarimo: this.userInfo.idUsuario,
            db: this.dbSelected,
          }),
        });
        const value = await response.json();
        if (response.status == 200) {
          if (value[0].Valor == 2) {
            this.codigoBarraFocus.$el.querySelector("input").focus();
            this.consultaTarimas(this.sucursalSeleccionada);
          } else {
            this.errorSound();
            this.$store.commit("snackbar", {
              snackbar: true,
              text: value[0].Descripcion,
            });
          }
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
        this.codigoBarras = "";
      } catch (error) {
        console.log(error);
      }
    },
    errorSound() {
      var audio = new Audio(sound);
      audio.play();
    },
    selectAllText(event) {
      event.target.select();
    },
    async BuscaEmpaquesPendientes() {
      //try {
      if (this.sucursalSeleccionada == "") {
        this.$store.commit("snackbar", {
          snackbar: true,
          text: "Seleccione una sucursal.",
        });
        this.sucursalDestinoFocus.$el.querySelector("input").focus();
        this.codigoBarras = "";
        return;
      }

      const date = await new Date(this.fechaIn);
      const date2 = await new Date();
      await date2.setDate(date2.getDate() + 1);
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}${month}${day}`;
      };
      const formattedDate = await formatDate(date);
      const formattedDate2 = await formatDate(date2);
      const response = await fetch(
        this.url + "/android/consulta-empaques-faltantes-tarima",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            SucursalOrig: this.userInfo.sucursal,
            SucursalDest: this.sucursalSeleccionada,
            FechaInicial: formattedDate,
            FechaFinal: formattedDate2,
            db: this.dbSelected,
          }),
        }
      );
      const value = await response.json();
      if (response.status == 200) {
        if (value.length > 0) {
          for (const item of value) {
            item.HoraTraspaso = await this.formatearfecha(item.HoraTraspaso);
          }
          this.listaPendientes = value;
        } else {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "No se encontro ningun empaque pendiente",
          });
        }
      } else {
        let body = {
          status: response.status,
          mensaje:
            response.status == 401 || response.status == 403
              ? value.mensaje
              : "Ocurrio un error, intente mas tarde",
        };
        this.$store.dispatch("responseError", body);
      }
      this.codigoBarras = "";
      //} catch (error) {
      //  console.log(error);
      //}
    },
    async formatearfecha(isoDate) {
      const date = await new Date(isoDate);

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses son 0-indexados
      const year = date.getFullYear();

      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    },
  },
};
</script>
<style scoped></style>

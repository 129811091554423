<template lang="">
  <v-container>
    <v-row>
      <v-col align="center">
        <h3>EMBARCANDO</h3>
        <v-divider style="border-width: thin 0 2px 0" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="sucSelected"
          :items="sucursales"
          item-text="sucursal"
          item-value="sucursal"
          hide-details
          :color="color"
          outlined
          dense
          multiple
          small-chips
          ref="sucursalDestino"
          label="Sucursal Destino"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-menu
          v-model="menuFechaInicio"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="fechaIn"
              label="Fecha Inicial"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              outlined
              hide-details
              :color="color"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fechaIn"
            no-title
            scrollable
            locale="es-ES"
            @input="menuFechaInicio = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6">
        <v-menu
          v-model="menuFechaFin"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          left
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="fechaFn"
              label="Fecha Final"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              outlined
              :color="color"
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fechaFn"
            no-title
            scrollable
            locale="es-ES"
            @input="menuFechaFin = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="tipoTraspasoSelecionados"
          dense
          :items="listaTipoTraspaso"
          item-text="TipoTraspaso"
          item-value="TipoTraspasoId"
          hide-details
          label="Tipo Traspaso"
          multiple
          small-chips
          ref="tipoTraspaso"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="codigoBarras"
          label="Codigo Empaque/Tarima"
          ref="codigoBarra"
          clearable
          dense
          hide-details
          :color="color"
          outlined
          @keydown.enter.prevent="BuscaEmpaque()"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import sound from "../assets/error.wav";
export default {
  name: "PantallaEmbarcando",
  data: () => ({
    sucSelected: [],
    sucursales: [],
    fechaIn: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    fechaFn: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000 + 86400000
    )
      .toISOString()
      .substr(0, 10),
    menuFechaInicio: false,
    menuFechaFin: false,
    tipoTraspasoSelecionados: [],
    listaTipoTraspaso: [],
    codigoBarras: "",
    codigoBarraFocus: null,
    sucursalDestinoFocus: null,
    tipoTraspasoFocus: null,
  }),
  computed: {
    ...mapState(["color", "secondColor", "url"]),
    navbarToggle: {
      get: function () {
        return this.$store.state.navbarToggle;
      },
      set: function (val) {
        localStorage.setItem("navbarToggle", val);
        this.$store.commit("setNavbarToggle", val);
      },
    },
    isAuthorized: {
      get: function () {
        return this.$store.state.isAuthorized;
      },
      set: function (val) {
        this.$store.state.isAuthorized = val;
      },
    },
    userInfo: {
      get: function () {
        return this.$store.state.userInfo;
      },
      set: function (val) {
        this.$store.state.userInfo = val;
      },
    },
    token: {
      get: function () {
        return this.$store.state.token;
      },
      set: function (val) {
        this.$store.state.token = val;
      },
    },
    dbSelected: {
      get: function () {
        return this.$store.state.dbSelected;
      },
      set: function (val) {
        localStorage.setItem("dbSelected", val);
        this.$store.commit("setdbSelected", val);
      },
    },
  },
  async mounted() {
    const credenciales = await JSON.parse(localStorage.getItem("credenciales"));
    if (credenciales != null) {
      this.token = credenciales.token;
      this.userInfo = credenciales.userInfo;
      this.isAuthorized = credenciales.isAuthorized;
    }
    const dbSelect = localStorage.getItem("dbSelected");
    if (dbSelect != null) {
      this.dbSelected = parseInt(dbSelect);
    }
    this.codigoBarraFocus = this.$refs.codigoBarra;
    this.sucursalDestinoFocus = this.$refs.sucursalDestino;
    this.tipoTraspasoFocus = this.$refs.tipoTraspaso;
    this.traerSucursales();
    this.traerTipoTraspasos();
  },
  methods: {
    async traerSucursales() {
      try {
        const response = await fetch(
          this.url + "/users/sucursalesNvo?db=" + this.dbSelected,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          if (value.length > 0) {
            this.sucursales = value;
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "Ocurrio un error.",
            });
          }
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async traerTipoTraspasos() {
      try {
        const response = await fetch(
          this.url + "/android/traer_tipos_traspasos?db=" + this.dbSelected,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          if (value.length > 0) {
            this.listaTipoTraspaso = value;
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "Ocurrio un error.",
            });
          }
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async BuscaEmpaque() {
      try {
        if (this.codigoBarras == "") {
          this.errorSound();
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Ingrese un codigo de barras.",
          });
          this.codigoBarras = "";
          return;
        }
        if (this.sucSelected.length == 0) {
          this.errorSound();
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Seleccione una sucursal.",
          });
          this.sucursalDestinoFocus.$el.querySelector("input").focus();
          this.codigoBarras = "";
          return;
        }
        if (this.tipoTraspasoSelecionados.length == 0) {
          this.errorSound();
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Seleccione un tipo de traspaso.",
          });
          this.tipoTraspasoFocus.$el.querySelector("input").focus();
          this.codigoBarras = "";
          return;
        }

        const date = new Date(this.fechaIn);
        const date1 = new Date(this.fechaFn);

        if (date > date1) {
          this.errorSound();
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "La fecha inicial no puede ser mayor que la fecha final.",
          });
          this.codigoBarras = "";
          return;
        }

        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}${month}${day}`;
        };
        const formattedDate = formatDate(date);
        const formattedDate1 = formatDate(date1);
        const sucursalesString = this.sucSelected.join(",");
        const tipoTraspasoString = this.tipoTraspasoSelecionados.join(",");
        let traspaso = "";
        let empaque = 0;
        let tarima = 0;
        const arr = this.codigoBarras.split("-");
        if (arr.length == 1) {
          tarima = arr[0];
        } else {
          traspaso = arr[0];
          empaque = arr[1];
        }

        const response = await fetch(
          this.url + "/android/verifica-empaque-tarima",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              SucursalDest: sucursalesString,
              FechaINI: formattedDate,
              FechaFIN: formattedDate1,
              TipoTraspaso: tipoTraspasoString,
              SucOrigen: this.userInfo.sucursal,
              NoTraspaso: traspaso,
              Empaque: empaque,
              NoTarima: tarima,
              IdUsuario: this.userInfo.idUsuario,
              db: this.dbSelected,
            }),
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          if (value[0].Existe == 2) {
            this.codigoBarraFocus.$el.querySelector("input").focus();
          } else {
            this.errorSound();
            this.$store.commit("snackbar", {
              snackbar: true,
              text: value[0].Descripcion,
            });
          }
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
        this.codigoBarras = "";
      } catch (error) {
        console.log(error);
      }
    },
    errorSound() {
      var audio = new Audio(sound);
      audio.play();
    },
  },
};
</script>
<style scoped></style>

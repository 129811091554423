import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"align":"center"}},[_c('h3',[_vm._v("SURTIDO "+_vm._s("(COMPROBACION)"))]),_c(VDivider,{attrs:{"thickness":"8px"}})],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"block":"","depressed":"","dark":"","hide-details":"","color":"rgba(206,18,45,100)"},on:{"click":function($event){return _vm.surtirSugerido()}}},[_vm._v(" surtir sugerido ")])],1),_c(VCol,[_c(VBtn,{attrs:{"block":"","depressed":"","dark":"","hide-details":"","color":"rgba(206,18,45,100)"},on:{"click":function($event){return _vm.AbrirTabla()}}},[_vm._v(" ver lista ")])],1)],1),(_vm.dialog)?_c(VRow,[_c(VCol,[_c(VCard,{attrs:{"color":"rgba(0,77,154,100)"}},[_c(VCardText,[_c(VDataTable,{attrs:{"dense":"","headers":_vm.pedidosHeaders,"items":_vm.pedidos,"mobile-breakpoint":"0"},on:{"click:row":_vm.handleRowClick}})],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
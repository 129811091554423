import Vue from "vue";
import Vuex from "vuex";
import mutations from "../Modules/mutations";
import state from "../Modules/state";
import actions from "../Modules/actions";

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  getters: {},
  mutations,
  actions,
  modules: {},
});

import Vue from "vue";
import VueRouter from "vue-router";
import PantallaLogin from "../views/PantallaLogin";
import PantallaSurtirConFolio from "../views/PantallaSurtirConFolio";
import PantallaSurtirSinFolio from "../views/PantallaSurtirSinFolio";
import PantallaSurtirPedido from "../views/PantallaSurtirPedido";
import PantallaSurtidoCompleto from "../views/PantallaSurtidoCompleto";
import PantallaComprobaTabla from "../views/PantallaComprobaTabla";
import ConteosPendientesVue from "@/views/ConteosPendientes.vue";
import ContadorInventario from "@/views/ContadorInventario.vue";
import PantallaEntarimado from "@/views/PantallaEntarimado.vue";
import PantallaEmbarcando from "@/views/PantallaEmbarcando.vue";
import ConsultaPendientes from "@/views/ConsultaPendientes.vue";
import LiberarEmbarque from "@/views/LiberarEmbarque.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "PantallaLogin",
    component: PantallaLogin,
  },
  {
    path: "/surtirfolio",
    name: "SurtidoFolio",
    component: PantallaSurtirConFolio,
  },
  {
    path: "/surtir",
    name: "Surtido",
    component: PantallaSurtirSinFolio,
  },
  {
    path: "/surtirpedido",
    name: "SurtidoPedido",
    component: PantallaSurtirPedido,
  },
  {
    path: "/surtirfinal",
    name: "SurtidoFinal",
    component: PantallaSurtidoCompleto,
  },
  {
    path: "/surtirprueba",
    name: "ComprobacionSurtido",
    component: PantallaComprobaTabla,
  },
  {
    path: "/conteos_pendientes",
    name: "ConteosPendientes",
    component: ConteosPendientesVue,
  },
  {
    path: "/contador",
    name: "ConteoInventario",
    component: ContadorInventario,
  },
  {
    path: "/entarimado",
    name: "Entarimado",
    component: PantallaEntarimado,
  },
  {
    path: "/embarcando",
    name: "Embarcando",
    component: PantallaEmbarcando,
  },
  {
    path: "/consultapendiente",
    name: "ConsultaPendientes",
    component: ConsultaPendientes,
  },
  {
    path: "/liberaembarque",
    name: "LiberarEmbarque",
    component: LiberarEmbarque,
  },
];

const router = new VueRouter({
  routes,
});

export default router;

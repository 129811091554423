<template lang="">
  <v-container>
    <v-row>
      <v-col align="center">
        <h3>FINALIZA PEDIDO</h3>
        <v-divider thickness="8px" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card color="rgba(0,77,154,100)">
          <v-card-text>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Pedido Surtido: {{ bodyForFetch2[0].NoBitacora }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Tipo de Pedido: {{ bodyForFetch2[0].TipoAbrev }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Sucursal Pide: {{ bodyForFetch2[0].SucPide }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          block
          depressed
          dark
          hide-details
          @click="hacerTraspaso()"
          color="rgba(206,18,45,100)"
        >
          finalizar pedido
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="auto">
      <v-card>
        <v-card-text>
          <br />
          <v-row justify="center">
            <v-col>
              El pedido {{ bodyForFetch2[0].NoBitacora }} ha sido actualizado
              correctamente</v-col
            >
          </v-row>
          <v-row>
            <v-btn color="rgba(0,77,154,100)" dark block @click="salir()"
              >Aceptar</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapState(["url", "bodyForFetch2", "token", "almacenPed", "userInfo"]),
  },
  methods: {
    async hacerTraspaso() {
      try {
        const response = await fetch(this.url + "/android/traspasar_pedido", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            SucPide: this.bodyForFetch2[0].SucPide,
            SucTras: this.bodyForFetch2[0].SucTras,
            Pedido: this.bodyForFetch2[0].NoBitacora,
            Almacen: this.almacenPed,
            UsuarioId: this.userInfo.idUsuario,
          }),
        });
        const value = await response.json();
        if (response.status == 200) {
          this.dialog = true;
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    salir() {
      let routeData = this.$router.resolve({
        name: "SurtidoFolio",
      });
      window.open(routeData.href, "_self");
    },
  },
};
</script>
<style lang=""></style>

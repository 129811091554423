<template lang="">
  <v-container>
    <v-row>
      <v-col align="center">
        <h3>SURTIDO</h3>
        <v-divider thickness="8px" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card color="rgba(0,77,154,100)">
          <v-card-text>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Numero de articulo: {{ bodyForFetch2[0].Itemno }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Descripcion: {{ bodyForFetch2[0].Descripcion }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Localizacion: {{ bodyForFetch2[0].Localizacion }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Existencia: {{ bodyForFetch2[0].Existencia }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Piezas: {{ bodyForFetch2[0].Pedido }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Tipo de Pedido: {{ bodyForFetch2[0].SucPide }} /
                  {{ bodyForFetch2[0].NoBitacora }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="subtitle-3" style="color: grey">DATOS A CAPTURAR</div>
        <v-divider thickness="8px" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card color="rgba(0,77,154,100)">
          <v-card-text>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Codigo de Barras:
                </div>
              </v-col>
              <v-col>
                <v-text-field
                  dark
                  v-model="barracodigo"
                  variant="underlined"
                  ref="barracod"
                  dense
                  @keydown.enter.prevent="verificarCodigo()"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="subtitle-3" style="color: white">
                  Cantidad a surtir:
                </div>
              </v-col>
              <v-col>
                <v-text-field
                  dark
                  v-model="qtysurtir"
                  variant="underlined"
                  type="number"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  block
                  depressed
                  dark
                  hide-details
                  :disabled="surtirBtnDisable"
                  color="rgba(206,18,45,100)"
                  v-on:click="btnSurtir()"
                >
                  surtir
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  block
                  depressed
                  dark
                  hide-details
                  :disabled="noHayBtnDisable"
                  color="rgba(206,18,45,100)"
                  v-on:click="btnNoHay()"
                >
                  no hay
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog1" width="auto">
      <v-card>
        <v-card-text>
          <br />
          <v-row justify="center">
            <v-col> {{ errormessage }} </v-col>
          </v-row>
          <v-row>
            <v-btn
              color="rgba(0,77,154,100)"
              dark
              block
              @click="dialog1 = false"
              >OK</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" width="auto">
      <v-card>
        <v-card-text>
          <br />
          <v-row justify="center">
            <v-col> Esta surtiendo mas de lo solicitado. ¿Estas seguro? </v-col>
          </v-row>
          <v-row>
            <v-btn
              color="rgba(0,77,154,100)"
              dark
              block
              @click="btnDialog2Yes()"
              >Si</v-btn
            >
            <v-btn
              color="rgba(0,77,154,100)"
              dark
              block
              @click="dialog2 = false"
              >No</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog3" width="auto">
      <v-card>
        <v-card-text>
          <br />
          <v-row justify="center">
            <v-col> Esta surtiendo mas de lo solicitado. ¿Estas seguro? </v-col>
          </v-row>
          <v-row>
            <v-btn
              color="rgba(0,77,154,100)"
              dark
              block
              @click="surtirarticulo()"
              >Si</v-btn
            >
            <v-btn color="rgba(0,77,154,100)" dark block @click="dialog3btnNo()"
              >No</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog4" width="auto">
      <v-card>
        <v-card-text>
          <br />
          <v-row justify="center">
            <v-col>
              Esta surtiendo menos de lo solicitado. ¿Estas seguro?
            </v-col>
          </v-row>
          <v-row>
            <v-btn
              color="rgba(0,77,154,100)"
              dark
              block
              @click="surtirarticulo()"
              >Si</v-btn
            >
            <v-btn
              color="rgba(0,77,154,100)"
              dark
              block
              @click="dialog4 = false"
              >No</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import sound from "../assets/error.wav";
export default {
  data: () => ({
    barracodigo: "",
    qtysurtir: 0,
    noHayBtnDisable: true,
    surtirBtnDisable: true,
    errormessage: "",
    dialog1: false,
    dialog3: false,
    dialog4: false,
    dialog2: false,
    sum: 0,
  }),
  computed: {
    ...mapState(["url", "bodyForFetch", "bodyForFetch2", "token", "userInfo"]),
  },
  async mounted() {
    this.barracodigo = "";
    this.$refs.barracod.focus();
    this.buttonSwitch();
  },
  watch: {
    qtysurtir() {
      this.buttonSwitch();
    },
  },
  methods: {
    buttonSwitch() {
      if (this.qtysurtir <= 0) {
        this.noHayBtnDisable = false;
        this.surtirBtnDisable = true;
      } else {
        this.noHayBtnDisable = true;
        this.surtirBtnDisable = false;
      }
    },
    errorSound() {
      var audio = new Audio(sound);
      audio.play();
    },
    async verificarCodigo() {
      try {
        const response = await fetch(this.url + "/android/verificar_codigo", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            itemno: this.bodyForFetch2[0].Itemno,
            codigo: this.barracodigo,
          }),
        });
        const value = await response.json();
        if (response.status == 200) {
          if (value.resultado[0].Res == 1) {
            this.sum =
              parseInt(this.qtysurtir == "" ? 0 : this.qtysurtir) +
              value.resultado[0].Caja;
            if (this.sum > this.bodyForFetch2[0].Pedido) {
              this.dialog2 = true;
              this.errorSound();
            } else {
              this.qtysurtir = this.sum;
            }
          } else {
            this.errorSound();
            this.errormessage = value.resultado[0].Descripcion;
            this.dialog1 = true;
          }
          this.barracodigo = "";
          this.$refs.barracod.focus();
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async btnNoHay() {
      try {
        const response = await fetch(this.url + "/android/surtir_articulo", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            SucPide: this.bodyForFetch2[0].SucPide,
            SucTras: this.bodyForFetch2[0].SucTras,
            Pedido: this.bodyForFetch2[0].NoBitacora,
            itemno: this.bodyForFetch2[0].Itemno,
            pzas: 0,
            partida: this.bodyForFetch2[0].Partida,
            partidas: this.bodyForFetch2[0].Partidas,
            captura: this.barracodigo != "" ? 2 : 1,
            UsuarioId: this.userInfo.idUsuario,
            Responsable: 0,
          }),
        });
        const value = await response.json();
        if (response.status == 200) {
          this.surteCompleto();
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    btnSurtir() {
      if (this.qtysurtir == 0) {
        this.errormessage =
          "La cantidad debe ser mayor que cero, favor de verificar.";
        this.dialog1 = true;
        this.errorSound();
      } else {
        if (this.qtysurtir > this.bodyForFetch2[0].Pedido) {
          this.dialog3 = true;
          this.errorSound();
        } else if (this.qtysurtir < this.bodyForFetch2[0].Pedido) {
          this.dialog4 = true;
          this.errorSound();
        } else {
          this.surtirarticulo();
        }
      }
    },
    dialog3btnNo() {
      this.qtysurtir == this.bodyForFetch2[0].Pedido;
      this.surtirarticulo();
    },
    btnDialog2Yes() {
      this.qtysurtir = this.sum;
      this.dialog2 = false;
    },
    async surtirarticulo() {
      this.dialog3 = false;
      this.dialog4 = false;
      try {
        const response = await fetch(this.url + "/android/checar_codigo", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            codigo: this.bodyForFetch.codigo,
          }),
        });
        const value = await response.json();
        if (response.status == 200) {
          var itemNoT = value.resultado;
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
      if (!this.bodyForFetch2[0].Itemno == itemNoT && !this.barracodigo == "") {
        this.errormessage =
          "El código de barras no corresponde al artículo que se está surtiendo";
        this.dialog1 = true;
        this.errorSound();
      } else {
        try {
          const response = await fetch(this.url + "/android/surtir_articulo", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              SucPide: this.bodyForFetch2[0].SucPide,
              SucTras: this.bodyForFetch2[0].SucTras,
              Pedido: this.bodyForFetch2[0].NoBitacora,
              itemno: this.bodyForFetch2[0].Itemno,
              pzas: parseInt(this.qtysurtir),
              partida: this.bodyForFetch2[0].Partida,
              partidas: this.bodyForFetch2[0].Partidas,
              captura: this.barracodigo != "" ? 2 : 1,
              UsuarioId: this.userInfo.idUsuario,
              Responsable: 0,
            }),
          });
          const value = await response.json();
          if (response.status == 200) {
            this.surteCompleto();
          } else {
            let body = {
              status: response.status,
              mensaje:
                response.status == 401 || response.status == 403
                  ? value.mensaje
                  : "Ocurrio un error, intente mas tarde",
            };
            this.$store.dispatch("responseError", body);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async surteCompleto() {
      try {
        const response = await fetch(
          this.url + "/android/surtir_pedido_tabla",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify(this.bodyForFetch),
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          if (value.resultado.length == 0) {
            let routeData = this.$router.resolve({
              name: "SurtidoFinal",
            });
            window.open(routeData.href, "_self");
          } else {
            let routeData = this.$router.resolve({
              name: "ComprobacionSurtido",
            });
            window.open(routeData.href, "_self");
          }
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang=""></style>

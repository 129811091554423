<template>
  <div>
    <v-container>
      <v-row style="padding-bottom: 3px">
        <v-col cols="12" style="text-align: center">
          <h3>Conteos Pendientes</h3>
        </v-col>
      </v-row>
      <v-divider style="border-width: thin 0 2px 0"></v-divider>
      <v-row style="padding-top: 10px; padding-bottom: 17px">
        <v-col cols="12">
          <v-autocomplete
            v-model="tipoFiltro"
            :items="listaTipoFil"
            outlined
            dense
            label="Filtrar por: "
            item-text="descripcion"
            item-value="idTipo"
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-btn v-on:click="Filtrar()" block :color="color" dark>
            FILTRAR
          </v-btn>
        </v-col>
      </v-row>
      <v-divider style="border-width: thin 0 2px 0"></v-divider>
      <v-row style="padding-top: 0px">
        <v-col cols="12">
          <v-list dense>
            <v-subheader>Selecciona un folio:</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in ListaPendientes"
                :key="i"
                @click="FolioSel(item)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.Comentario"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ConteosPendientes",
  data: () => ({
    tipoFiltro: 0,
    listaTipoFil: [
      { idTipo: 0, descripcion: "TODOS" },
      { idTipo: 1, descripcion: "AUTOMATICOS" },
      { idTipo: 2, descripcion: "MI USUARIO" },
    ],
    ListaPendientes: [],
  }),
  computed: {
    ...mapState([
      "color",
      "secondColor",
      "url",
      "token",
      "userInfo",
      "dbSelected",
    ]),
    folioSeleccionado: {
      get: function () {
        return this.$store.state.folioSeleccionado;
      },
      set: function (val) {
        this.$store.commit("setFolioSeleccionado", val);
      },
    },
    navbarToggle: {
      get: function () {
        return this.$store.state.navbarToggle;
      },
      set: function (val) {
        localStorage.setItem("navbarToggle", val);
        this.$store.commit("setNavbarToggle", val);
      },
    },
  },
  methods: {
    async Filtrar() {
      try {
        const response = await fetch(
          this.url + "/inventario/Conteos_Pendientes",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              tipo:
                this.tipoFiltro == 2
                  ? this.userInfo.idUsuario
                  : this.tipoFiltro,
              Sucursal: this.userInfo.sucursal,
              db: this.dbSelected,
            }),
          }
        );
        const value = await response.json();
        if (response.status == 200) {
          if (value.length > 0) {
            this.ListaPendientes = value;
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "No se encontraron pendientes.",
            });
          }
        } else {
          let body = {
            status: response.status,
            mensaje:
              response.status == 401 || response.status == 403
                ? value.mensaje
                : "Ocurrio un error, intente mas tarde",
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    FolioSel(folio) {
      localStorage.setItem("FolioSelected", folio.Comentario);
      this.folioSeleccionado = folio;
      this.navbarToggle = 1;
      this.$router.push({ name: "ConteoInventario" });
    },
  },
};
</script>
<style scoped></style>

import router from "@/router";

export default {
  setCredenciales: (state, payload) => {
    const credenciales = JSON.parse(payload);
    state.isAuthorized = credenciales.isAuthorized;
    state.userInfo = credenciales.userInfo;
    state.token = credenciales.token;
  },
  snackbar: (state, payload) => {
    state.showSnackbar = payload.snackbar;
    state.message = payload.text;
  },
  setUserInfo: (state, payload) => {
    state.userInfo = payload;
  },
  setToken: (state, payload) => {
    state.token = payload;
  },
  setSucursales: (state, payload) => {
    state.sucursales = payload;
  },
  setTipoMov: (state, payload) => {
    state.tipoMov = payload;
  },
  setIsAuthorized: (state, payload) => {
    state.isAuthorized = payload;
  },
  setNavbarToggle: (state, payload) => {
    state.navbarToggle = payload;
    localStorage.setItem("navbarToggle", payload);
  },
  setNavDrawerToggle: (state, payload) => {
    state.navDrawerToggle = payload;
  },
  setArea: (state, payload) => {
    state.area = payload;
  },
  setBodyForFetch: (state, payload) => {
    state.bodyForFetch = payload;
  },
  setBodyForFetch2: (state, payload) => {
    state.bodyForFetch2 = payload;
  },
  setAlmacenPed: (state, payload) => {
    state.almacenPed = payload;
  },
  ExitApp: (state) => {
    state.isAuthorized = false;
    state.userInfo = {};
    state.token = "";
    state.folioSeleccionado = "";
    localStorage.clear();
    router.push({ name: "PantallaLogin" });
    //window.location.reload();
  },
  setTittle: (state, payload) => {
    state.tittle = payload;
  },
  setFolioSeleccionado: (state, payload) => {
    state.folioSeleccionado = payload;
  },
  setdbSelected: (state, payload) => {
    state.dbSelected = payload;
  },
  setHayEmpaquesPendientes: (state, payload) => {
    state.hayEmpaquesPendientes = payload;
  },
  setSucursalesSeleccionadas: (state, payload) => {
    state.sucursalesSeleccionadas = payload;
  },
};
